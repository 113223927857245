<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Yetki Yerleri </template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_YetkiYeriModal()" />
        </div>
        <DataTable v-if="DataTable_render" :columns="columns" :data="YetkiYerleriListesi" :editbutton="true"
          v-on:set-child-data="getDataChild"  />
      </template>
    </Card>

    <Dialog v-model:visible="YetkiYeriModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Yeni Yetki Yeri</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanYerAdi">Yer Adı</span>
          <InputText type="text" v-model="YerAdi" aria-labelledby="spanYerAdi"/>
        </div>
        <div class="p-field p-col-6">
          <span for="spanYetkiTanim">Yer Tanımı</span>
          <InputText type="text" v-model="YetkiTanim" aria-labelledby="spanYetkiTanim" />
        </div>
        <div class="p-field p-col-6">

        </div>
        <div class="p-field p-col-6">
          <span for="spanIcon">Icon</span>
          <Dropdown filter id="drpIcon" v-model="SelectedIcon" :options="IconListesi" dataKey="Icon_rowid" optionLabel="IconAd"
            placeholder="Icon Seç.." class="w-full md:w-14rem">
            <template #value="slotProps">
              <div v-if="slotProps.value" class="flex align-items-center">
                <div>
                  <i :class="`pi ${slotProps.value.IconAd} `"></i>
                  {{ slotProps.value.IconAd }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center">
                <div>
                  <i :class="`pi ${slotProps.option.IconAd}`"></i>
                  {{ slotProps.option.IconAd }}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="p-field p-col-6">
          <span for="spanSoyad">Rol</span>
          <Dropdown filter id="drpRol" v-model="SelectedRol" :options="RolListesi" optionLabel="Ad" optionValue="Rol_rowid"
            placeholder="Seçiniz..."  @change="RolChange" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanWebSayfa">WebSayfa</span>
          <Dropdown filter id="drpWebSayfa" v-model="SelectedWebSayfa" :options="WebSayfaListesi" optionLabel="Ad"
            optionValue="WebSayfa_rowid" placeholder="Seçiniz..."  @change="WebSayfaChange" />
        </div>

        <div class="p-field p-col-6">
          <span for="spanUstMenu">UstMenu</span>
          <Dropdown filter id="drpUstMenu" v-model="SelectedUstMenu" :options="UstMenuListesi" optionLabel="Ad"
            optionValue="UstMenu" placeholder="Seçiniz..." @change="RolChange" />
        </div>

        <div class="p-field p-col-6">
          <span for="spanSira">Sıra</span>
          <InputNumber v-model="Sira" inputId="horizontal-buttons" showButtons buttonLayout="horizontal" :step="1"
            decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" :min="0" :max="999" />
        </div>
        <div class="p-field p-col-6">
          <div class="flex align-items-center">
            <Checkbox v-model="Separator" :binary="true" />
            <label for="ingredient1" class="ml-2"> Separator </label>
          </div>
        </div>
        <div class="p-field p-col-6">
          <div class="flex align-items-center">
            <Checkbox v-model="MenudeGoster" :binary="true" />
            <label for="ingredient2" class="ml-2"> Menüde Göster </label>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._YetkiYeriModal()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
// import { useVuelidate } from "@vuelidate/core";
import GlobalServis from "../../../../services/GlobalServis";
import DataTable from "../../DataTable/BasicDataTable.vue";
// import { required, helpers } from "@vuelidate/validators";

export default {
  data() {
    return {
      PopUpDurumu: true, // True = Yeni , False = Edit
      DataTable_render: true,
      YetkiYeriModal: false,
      YetkiYerleriListesi: [],
      WebSayfaListesi: [],
      RolListesi: [],
      IconListesi: [],
      UstMenuListesi: [],
      columns: [
        {
          field: "YerAdi",
          header: "Yer Adı",
        },
        {
          field: "YetkiTanim",
          header: "Yetki Tanım",
        },
        {
          field: "Url",
          header: "URL",
        },
        {
          field: "RolAdi",
          header: "Rol",
        },
      ],
      YetkiYeri_rowid: null,
      YerAdi: "",
      YetkiTanim: "",
      SelectedRol: "",
      UstMenu: "",
      Sira: "",
      SelectedIcon : null,
      SelectedWebSayfa: "",
      Separator: false,
      MenudeGoster: true,
      SelectedUstMenu: "",
    };
  },
  methods: {
    InitServis() {
      this._GetRolListesi();
      this._GetIconListesi();
      this._GetUstMenu();
      this._GetWebSayfa()
    },
    _YetkiYeriModal() {
      this.YetkiYeriModal = !this.YetkiYeriModal;
      if (!this.YetkiYeriModal) {
        //this.resetForm();
      }
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    handleClick(isFormValid) {
      
        let data = {
          YetkiYeri_rowid: this.YetkiYeri_rowid,
          pRol: this.SelectedRol,
          Ad: this.YerAdi,
          Tanim: this.YetkiTanim,
          Separator: this.Separator,
          MenudeGoster: this.MenudeGoster,
          Sira: this.Sira,
          pIcon: this.SelectedIcon.Icon_rowid,
          UstMenu: this.SelectedUstMenu,
          WebSayfa_rowid: this.SelectedWebSayfa
        };
        if (this.PopUpDurumu) {
          // Yeni Yetki Yeri
          GlobalServis.GlobalServis("POST", "setYetkiYeri", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Kullanıcı oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._YetkiYeriModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          //Edit Yetki Yeri
          GlobalServis.GlobalServis("POST", "UpdateYetkiYeri", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Yetki Yeri düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this._YetkiYeriModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      
    },
    getDataChild(getdata_child) {
      this.ResetForm();
      this.PopUpDurumu = false;
      this.YetkiYeri_rowid = getdata_child.data.YetkiYeri_rowid;
      let data = {
        YetkiYeri_rowid: getdata_child.data.YetkiYeri_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetYetkiYeriInfo", data)
        .then((res) => {
          if (res.status == 200) {
            this.YerAdi = res.data[0].YerAdi;
            this.YetkiTanim = res.data[0].YetkiTanim;
            this.SelectedRol = res.data[0].pRol;
            this.SelectedIcon = res.data[0].pIcon;
            this.SelectedWebSayfa = res.data[0].WebSayfa_rowid;
            // this.SelectedUstMenu = res.data[0].UstMenu;
            this.SelectedUstMenu = res.data[0].UstMenu;
            this.Sira = res.data[0].Sira;
            this.RolAciklama = res.data[0].RolAciklama;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        })
        .then(() => {
          this._YetkiYeriModal();
        });
    },
    _GetRolListesi() {
      GlobalServis.GlobalServis("GET", "GetRol", "")
        .then((res) => {
          if (res.status == 200) {
            this.RolListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        })
        .finally(() => {
          this._GetYetkiYeriListesi();
        });
    },
    _GetYetkiYeriListesi() {
      GlobalServis.GlobalServis("GET", "GetYetkiYeri", "").then((res) => {
        if (res.status == 200) {
          this.YetkiYerleriListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _GetIconListesi() {
      GlobalServis.GlobalServis("GET", "GetIconTablosu", "").then((res) => {
        if (res.status == 200) {
          this.IconListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _GetUstMenu() {
      GlobalServis.GlobalServis("GET", "GetUstMenuTablosu", "").then((res) => {
        if (res.status == 200) {
          this.UstMenuListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    _GetWebSayfa() {
      GlobalServis.GlobalServis("GET", "GetWebSayfa", "").then((res) => {
        if (res.status == 200) {
          this.WebSayfaListesi = res.data;
        } else if (res.status == 400) {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Önemli bir hata meydana geldi",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Hata",
            detail: "Bilinmeyen bir hata meydana geldi",
            life: 3000,
          });
        }
      });
    },
    ResetForm() {
      this.YetkiYeri_rowid = null;
      this.YerAdi = "";
      this.YetkiTanim = "";
      this.SelectedRol = "";
      this.UstMenu = "";
      this.Sira = "";
      this.SelectedIcon = "";
      this.Separator = false;
      this.SelectedUstMenu = "";
      this.SelectedWebSayfa = "";
      this.PopUpDurumu = true;
    }
  },
  watch: {
    RolID: function (val) {
      this._GetYetkiYeriListesi();
    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },
  // validations() {
  //   return {
  //     Sira: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //     // SelectedUstMenu: {
  //     //     required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     // },
  //     SelectedIcon: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //     YerAdi: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //     YetkiTanim: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //     SelectedRol: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //     SelectedWebSayfa: {
  //       required: helpers.withMessage("Bu alan boş bırakılamaz", required),
  //     },
  //   };
  // },
};
</script>

<style lang="scss" scoped></style>
